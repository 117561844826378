// Footer.js
import React from 'react';
import {Link} from "react-router-dom";

const footerStyle = {
  position: 'fixed',
  left: '0',
  bottom: '0',
  width: '100%',
  textAlign: 'center',
  backgroundColor: '#f9f9ff',
  padding: '10px 0',
  fontFamily: 'Gilroy'
};
const Footer = () => (
  <footer style={footerStyle}>
    <p>© {new Date().getFullYear()} Connected College. All rights reserved.</p>
    <div>
      <Link to="/terms-of-service">Terms of Service</Link> |
      <Link to="/privacy-policy">Privacy Policy</Link>
    </div>
  </footer>
);

export default Footer;
