import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Auth} from 'aws-amplify';
import LoadingCloak from "../LoadingCloak/LoadingCloak";

const ProtectedRoute = ({children}) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        setUser(user);
      })
      .catch(() => {
        navigate('/signin');
      });
  }, [navigate]);

  if (!user) {
    return <LoadingCloak/>
  }

  return children;
};
export default ProtectedRoute;
