export const savePublicQuizResponse = async (axios, authToken, quizId, answers) => {
  return axios.post(`me/quizzes/${quizId}`,
    {
      "items": [...answers]
    }, {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    })
}

export const deleteAccount = async (axios, authToken) => {
  return axios.delete(`me`, {
    headers: {
      'Authorization': `Bearer ${authToken}`
    }
  })
}
