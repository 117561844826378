import React, {useEffect, useState} from 'react';
import {Auth} from "aws-amplify";
import {useNavigate} from "react-router-dom";
import LoadingCloak from "../../components/LoadingCloak/LoadingCloak";

const Logout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();


  const handleSignOut = async () => {
    try {
      setIsLoading(true);
      await Auth.signOut();
      setIsLoading(false);
      navigate('/signin');
    } catch (error) {
      console.log('error signing out: ', error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    handleSignOut();
  });

  return (
    <div>
      {isLoading && <LoadingCloak/>}
    </div>
  );
};

export default Logout;
