import React from 'react';
import './LoadingCloak.css';

const LoadingCloak = () => {
  return (
    <div className="loading-cloak">
      <div className="loading-indicator">
        <svg xmlns="http://www.w3.org/2000/svg" width="240" height="10" viewBox="0 0 240 10" fill="none">
          <path d="M0 5C0 2.23858 2.23858 0 5 0H235C237.761 0 240 2.23858 240 5C240 7.76142 237.761 10 235 10H5C2.23857 10 0 7.76142 0 5Z" fill="#EEF6FF"/>
        </svg>
        <div className="moving-part">
          <svg xmlns="http://www.w3.org/2000/svg" width="98" height="8" viewBox="0 0 98 8" fill="none">
            <path d="M0 4C0 1.79086 1.79086 0 4 0H94C96.2091 0 98 1.79086 98 4C98 6.20914 96.2091 8 94 8H4C1.79086 8 0 6.20914 0 4Z" fill="#2980F2"/>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default LoadingCloak;