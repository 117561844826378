import React from 'react';
import './RegularButton.css';

const RegularButton = ({onClickHandler, buttonText, showStars = true}) => {

  return (
    <button type="button" className="regular-button" onClick={onClickHandler}>
      {showStars && (
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M1 10C4.31371 10 7 7.31371 7 4C7 7.31371 9.68629 10 13 10C9.68629 10 7 12.6863 7 16C7 12.6863 4.31371 10 1 10Z" fill="#7E85F9"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7 22C10.3137 22 13 19.3137 13 16C13 19.3137 15.6863 22 19 22C15.6863 22 13 24.6863 13 28C13 24.6863 10.3137 22 7 22Z" fill="#7E85F9"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M19 16C22.3137 16 25 13.3137 25 10C25 13.3137 27.6863 16 31 16C27.6863 16 25 18.6863 25 22C25 18.6863 22.3137 16 19 16Z" fill="#7E85F9"/>
      </svg> )}
      {buttonText}
    </button>
  )
};

export default RegularButton;
