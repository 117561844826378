import React from 'react';
import './QuizQuestion.css';

const QuizQuestion = ({ question, choices, onChoiceSelected }) => {
  return (
    <div className="quiz-question">
      <div className="question">{question}</div>
      <div className="choices">
        {choices.map((choiceObj, index) => (
          <button
            key={choiceObj.order} // Use choiceObj.id instead of index for key
            className="choice-button"
            onClick={() => onChoiceSelected(choiceObj)} // Pass the entire choice object
          >
            {choiceObj.choice}
          </button>
        ))}
      </div>
    </div>
  );
}

export default QuizQuestion;
