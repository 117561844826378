import React, {useState, useRef, useEffect} from 'react';
import './OtpConfirmation.css';
import {InputMask} from "@react-input/mask";
import SubmitButton from "../contorols/SubmitButton/SubmitButton";
import RegularButton from "../contorols/RegularButton/RegularButton";
import {useNavigate} from "react-router-dom";
import {Auth, Hub} from "aws-amplify";
import LoadingCloak from "../LoadingCloak/LoadingCloak";
import {ERROR_MESSAGE, OTP_ERROR_MESSAGE} from "../../utils/Constants";

const OTPForm = ({formDescription, userName, cognitoUser, signInExperience = true}) => {
  const TIMER_DURATION = 30;
  const [otpErrorMessage, setOtpErrorMessage] = useState(OTP_ERROR_MESSAGE);
  const [currentCognitoUser, setCurrentCognitoUser] = useState(cognitoUser);
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [timer, setTimer] = useState(TIMER_DURATION);
  const [formValid, setFormValid] = useState(true);
  const [otpTouched, setOtpTouched] = useState(false);
  const inputsRef = useRef([]);
  let intervalRef = useState();

  const navigate = useNavigate();
  const isButtonDisabled = !otp.includes("") && timer !== 0;


  const clearTimer = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const startTimer = () => {
    clearTimer();
    setTimer(TIMER_DURATION);

    intervalRef.current = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);
  };

  useEffect(() => {
    if (!cognitoUser && signInExperience) {
      navigate('/signin');
    }

    if (!signInExperience) {
      Hub.listen('auth', ({payload}) => {
        const {event} = payload;
        if (event === 'autoSignIn') {
          setIsLoading(false);
          navigate('/welcome');
        } else if (event === 'autoSignIn_failure') {
          console.log(event);
        }
      });
    }
  }, [cognitoUser, navigate]);

  useEffect(() => {
    startTimer();
    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (timer === 0) {
      clearTimer(); // Stop timer when it reaches 0
    }
  }, [timer]);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtpTouched(true)
    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  const formatTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text').slice(0, 6);
    if (pasteData.length === 6 && /^[0-9]{6}$/.test(pasteData)) {
      const newOtp = pasteData.split('');
      setOtp(newOtp);
      // Focus the last input after pasting
      inputsRef.current[5].focus();
    }
  };


  const resendConfirmationCode = async () => {
    try {
      setIsLoading(true);
      if (signInExperience) {
        setCurrentCognitoUser(await Auth.signIn(userName.toUpperCase(), userName.toUpperCase()));
      } else {
        await Auth.resendSignUp(userName.toUpperCase());
      }
      startTimer();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error('Error resending code: ', err);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      let code = otp.join('');
      if (signInExperience) {
        await Auth.sendCustomChallengeAnswer(currentCognitoUser, code);
      } else {
        await Auth.confirmSignUp(userName.toUpperCase(), code);
      }
      setIsLoading(false);
      if (signInExperience) {
        Auth.currentSession().then(res => {
          navigate('/welcome');
        })
      } else {
        navigate('/signin', {state: {status: 'successSignUp', userName: userName}});
      }
    } catch (e) {
      setIsLoading(false);
      setFormValid(false);
      console.error(e)
    }
  };

  return (
    <div>
      {isLoading && <LoadingCloak/>}
      <div className="otp-form-form-container">
        <h1 className="otp-form-form-header">Confirm</h1>
        <p className="otp-form-form-description">
          Type code from email
          to complete {formDescription}
        </p>
        <form onSubmit={handleSubmit} className="otp-form">
          <div className="otp-inputs-container">
            {otp.map((data, index) => (

              <InputMask
                key={index}
                mask="_"
                type="text"
                maxLength="1"
                value={data}
                onChange={(e) => handleChange(e.target, index)}
                onFocus={(e) => e.target.select()}
                ref={(ref) => inputsRef.current[index] = ref}
                className={`otp-input ${!formValid && otpTouched ? 'invalid' : ''}`}
                placeholder="_"
                onPaste={index === 0 ? handlePaste : null}
                replacement={{_: /\d/}}
              />

            ))}
          </div>
          {!formValid && otpTouched && (
            <div className="error-message">{otpErrorMessage}</div>
          )}
          <div className="otp-buttons-container">
            <button type="button"
                    className={`request-new-code ${timer > 0 ? 'disabled' : ''}`}
                    onClick={resendConfirmationCode}
                    disabled={timer > 0}>
              Request new code {timer > 0 ? (formatTime()) : null}
            </button>
            <RegularButton buttonText={signInExperience ? "Back to Sign In" : "Back to Sign Up"} onClickHandler={() => navigate("/welcome")}/>
            <SubmitButton isButtonDisabled={isButtonDisabled} buttonText="Confirm Code"/>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OTPForm;
