import React, {useContext, useEffect, useState} from 'react';
import './ThankYouPage.css'; // Make sure to create and import the CSS file
import AppleStore from '../../assets/icons/App-Store.png';
import GooglePlay from '../../assets/icons/google-play.png';
import {Auth} from "aws-amplify";
import {useNavigate} from "react-router-dom";
import RegularButton from "../../components/contorols/RegularButton/RegularButton";
import LoadingCloak from "../../components/LoadingCloak/LoadingCloak";
import SubmitButton from "../../components/contorols/SubmitButton/SubmitButton";
import {PUBLIC_QUIZ_ID, PUBLIC_QUIZ_STATE_SUBMIT} from "../../utils/Constants";
import AxiosContext from "../../services/AxiosContext";
import {deleteAccount, savePublicQuizResponse} from "../../services/userService";
import DangerButton from "../../components/contorols/DangerButton/DangerButton";

const ThankYouPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [jwtToken, setJwtToken] = useState("Initial");
  const navigate = useNavigate();
  const axios = useContext(AxiosContext);
  const checkUser = async () => {
    try {
      Auth.currentSession().then(res => {
        setJwtToken(res.getIdToken().getJwtToken());
      })
    } catch {
      navigate('/signin');
    }
  }

  const handleSignOut = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await Auth.signOut();
      setIsLoading(false);
      navigate('/signin');
    } catch (error) {
      console.log('error signing out: ', error);
      setIsLoading(false);
    }
  }

  const copyToClipboard = async (textToCopy) => {
    await navigator.clipboard.writeText(textToCopy);
  };

  const handleDeleteAccount = async () => {
    // Confirmation dialog
    const confirmDelete = window.confirm("Are you sure you want to delete your account? This action cannot be undone.");
    if (!confirmDelete) {
      return; // Stop the function if the user cancels
    }

    try {
      setIsLoading(true);
      await deleteAccount(axios, jwtToken);
      await Auth.deleteUser();
      navigate('/signin');
    } catch (err) {
      console.error('Error during account deletion:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  useEffect(() => {
    if (jwtToken !== "Initial") {
      const savedQuizStateSubmit = localStorage.getItem(PUBLIC_QUIZ_STATE_SUBMIT);
      if (!savedQuizStateSubmit) {
        const savedQuizState = localStorage.getItem(PUBLIC_QUIZ_ID);
        if (savedQuizState) {
          const {userResponses} = JSON.parse(savedQuizState);
          console.log(userResponses);
          savePublicQuizResponse(axios, jwtToken, "1", userResponses)
            .then(res => {
              localStorage.removeItem(PUBLIC_QUIZ_ID);
              localStorage.setItem(PUBLIC_QUIZ_STATE_SUBMIT, "true");
            })
            .catch(err => console.log(err));
        }
      }
    }
  }, [jwtToken]);

  return (
    <div>
      {isLoading && <LoadingCloak/>}
      <div className="thank-you-page">
        <h1>Thank you for choosing us</h1>
        <form onSubmit={handleSignOut}>

          <RegularButton buttonText="Copy JWT Token" onClickHandler={() => copyToClipboard(jwtToken)}/>
          <DangerButton buttonText="Delete Account" isButtonDisabled={true}
                        onClickHandler={() => handleDeleteAccount()}/>
          <SubmitButton buttonText="Sign Out" isButtonDisabled={true}/>
        </form>


        <div className="download-area">
          <a href="https://apps.apple.com/us/app/connected-college/id6476144472" target="_blank" rel="noopener noreferrer">
            <img src={AppleStore} alt="Apple Store"/>
          </a>
          <a href="https://play.google.com/store/apps/details?id=college.connected&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
            <img src={GooglePlay} alt="Google Play"/>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
