import React from 'react';
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_CONNECTED_API_BASE_URL}`,
});

const AxiosContext = React.createContext(axiosInstance);

export default AxiosContext;
