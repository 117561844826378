import React, {useEffect, useState} from 'react';
import './SignInForm.css';
import SubmitButton from "../contorols/SubmitButton/SubmitButton";
import RegularButton from "../contorols/RegularButton/RegularButton";
import {useLocation, useNavigate} from "react-router-dom";
import {Auth} from 'aws-amplify';
import OtpConfirmation from "../OtpConfirmation/OtpConfirmation";
import LoadingCloak from "../LoadingCloak/LoadingCloak";
import SocialSignins from "../SocialSignins/SocialSignins";
import {EMAIL_REGEX, ERROR_MESSAGE} from "../../utils/Constants";

const SignInForm = () => {
  const location = useLocation();
  const signedUpUserName = location.state && location.state.userName;

  const [formData, setFormData] = useState({
    email: signedUpUserName,
  });


  const [isSignIn, setIsSignIn] = useState(true);
  const [emailErrorMessage, setEmailErrorMessage] = useState(ERROR_MESSAGE);
  const [emailTouched, setEmailTouched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [otpState, setOtpState] = useState("Initial");
  const [cognitoUser, setCognitoUser] = useState(false);
  const navigate = useNavigate();

  // Update form validation status every time formData changes
  useEffect(() => {
    const {email} = formData;
    const isValidForm = email ? EMAIL_REGEX.test(email) : false;
    setFormValid(isValidForm);
  }, [formData]);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        navigate("/welcome");
        setIsLoading(false);
      })
      .catch(() => {
      });

  });

  const handleInputChange = (e) => {
    const {name, value, type, checked} = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });

    if (name === 'email') {
      setEmailTouched(true);
      setEmailErrorMessage(ERROR_MESSAGE);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    // Implement your form submission logic here
    try {
      setIsLoading(true);
      const user = await Auth.signIn(formData.email.toUpperCase(), formData.email.toUpperCase());
      if (user) {
        setOtpState("OtpSent");
        setCognitoUser(user);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setFormValid(false);
      setEmailErrorMessage(e.message)
      if (e.message === "User is not confirmed.") {
        try {
          const user = await Auth.resendSignUp(formData.email.toUpperCase());
          if (user) {
            setIsSignIn(false);
            setOtpState("OtpSent");
          }
        } catch (e) {
          setEmailErrorMessage(e.message);
          setIsLoading(false);
        }
      }
    }

  };

  if (otpState !== "OtpSent") {

    return (
      <div>
        {isLoading && <LoadingCloak/>}
        <div className="sign-in-form-container">
          <h1 className="sign-in-form-header">Sign In</h1>
          <form onSubmit={handleSubmit} className="sign-in-form">
            <label htmlFor="email" className="sign-in-form-label">Your login (Email)</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="email@gmail.com"
              className={`sign-in-input-field ${!formValid && emailTouched ? 'invalid' : ''}`}
            />
            {!formValid && emailTouched && (
              <div className="error-message">{emailErrorMessage}</div>
            )}

            <SocialSignins cloakFunction={() => setIsLoading(true)}/>
            <RegularButton buttonText="Proceed To Sign Up" onClickHandler={() => navigate("/signup")}/>
            <SubmitButton isButtonDisabled={formValid} buttonText="Send Code"/>
          </form>
        </div>
      </div>
    )
      ;
  } else {
    return (<OtpConfirmation formDescription={"Sign In"} cognitoUser={cognitoUser} userName={formData.email}
                             signInExperience={isSignIn}/>);
  }
};

export default SignInForm;
