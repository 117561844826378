import React, {useState, useEffect} from 'react';
import './SignUpForm.css';
import {InputMask} from "@react-input/mask";
import SubmitButton from "../contorols/SubmitButton/SubmitButton";
import RegularButton from "../contorols/RegularButton/RegularButton";
import {useNavigate} from "react-router-dom";
import {Auth} from "aws-amplify";
import LoadingCloak from "../LoadingCloak/LoadingCloak";
import OtpConfirmation from "../OtpConfirmation/OtpConfirmation";
import SocialSignins from "../SocialSignins/SocialSignins";
import {EMAIL_REGEX, ERROR_MESSAGE} from "../../utils/Constants";


const SignUpForm = () => {

  const [emailErrorMessage, setEmailErrorMessage] = useState(ERROR_MESSAGE);
  const [emailServerError, setEmailServerError] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [firstNameTouched, setFirstNameTouched] = useState(false);
  const [lastNameTouched, setLastNameTouched] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    termsAccepted: false,
  });

  const [otpState, setOtpState] = useState("Initial");
  const [formValid, setFormValid] = useState(false);
  const navigate = useNavigate();


  const formatPhoneNumber = (phoneNumber) => {
    return phoneNumber?.replace(/[ ()-]/g, '');
  }

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
    return phoneRegex.test(phoneNumber);
  };

  const isPhoneValid = (phoneNumber) => {
    if (!phoneNumber) return true;

    return validatePhoneNumber(formatPhoneNumber(phoneNumber));
  }

  const isNameValid = (name) => {
    const nameRegex = /^[a-z ,.'-]+$/i;
    return name ? nameRegex.test(name) : false
  }


  const isEmailValid = (email) => {
    return email ? EMAIL_REGEX.test(email) : false
  }

  useEffect(() => {
    const {email, firstName, lastName, termsAccepted} = formData;
    const isValidForm = firstName && lastName && isEmailValid(email) && termsAccepted;
    setFormValid(isValidForm);
  }, [formData]);
  const handleInputChange = (e) => {
    const {name, value, type, checked} = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });

    if (name === 'email') {
      setEmailTouched(true);
      setEmailServerError(false);
      setEmailErrorMessage(ERROR_MESSAGE);
    }
    else if (name === 'firstName') setFirstNameTouched(true);
    else if (name === 'lastName') setLastNameTouched(true);

  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Implement your form submission logic here
    try {
      setIsLoading(true);
      await Auth.signUp(
        {
          username: formData.email.toUpperCase(),
          password: formData.email.toUpperCase(),
          attributes: {
            name: formData.firstName + ' ' + formData.lastName,
            phone_number: formatPhoneNumber(formData.phone)
          },
        });
      setIsLoading(false);
      setOtpState("OtpSent");
    } catch (e) {
      setIsLoading(false);
      setEmailErrorMessage(e.message);
      setEmailServerError(true);
      console.error(e);
    }

  };

  if (otpState !== "OtpSent") {
    return (
      <div>
        {isLoading && <LoadingCloak/>}
        <div className="sign-up-form-container">
          <h1 className="sign-up-form-header">Well done</h1>
          <p className="sign-up-form-description">
            You have completed the quiz,
            register to complete more and get
            access to educational content
          </p>
          <form onSubmit={handleSubmit} className="sign-up-form">
            <label htmlFor="email" className="sign-up-form-label">Your login (Email)</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="email@gmail.com"
              className={`sign-up-input-field ${(!isEmailValid(formData.email) || emailServerError) && emailTouched ? 'invalid' : ''}`}
            />
            {emailServerError && emailTouched && (
              <div className="error-message">{emailErrorMessage}</div>
            )}
            <label htmlFor="firstName" className="sign-up-form-label">First Name</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              placeholder="Holly"
              className={`sign-up-input-field ${!isNameValid(formData.firstName) && firstNameTouched ? 'invalid' : ''}`}
            />
            <label htmlFor="lastName" className="sign-up-form-label">Last Name</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              placeholder="Campbell"
              className={`sign-up-input-field ${!isNameValid(formData.lastName) && lastNameTouched ? 'invalid' : ''}`}
            />
            <label htmlFor="phone" className="sign-up-form-label">Phone</label>
            <InputMask
              mask="+_ (___) ___-__-__"
              value={formData.phone}
              onChange={handleInputChange}
              placeholder="+1 (___) ___-__-__"
              replacement={{_: /\d/}}
              className={`sign-up-input-field ${!isPhoneValid(formData.phone) ? 'invalid' : ''}`}
              name="phone"
              id="phone"
            />
            <label className="sign-up-checkbox-container">
              Accept Terms and Conditions
              <input
                type="checkbox"
                name="termsAccepted"
                checked={formData.termsAccepted}
                onChange={handleInputChange}
              />
              <span className="checkmark"></span>
            </label>

            <SocialSignins cloakFunction={() => setIsLoading(true)}/>
            <RegularButton buttonText="Proceed To Sign In" onClickHandler={() => navigate("/signin")}/>
            <SubmitButton isButtonDisabled={formValid} buttonText="Done"/>
          </form>
        </div>
      </div>
    );
  } else {
    return (<OtpConfirmation formDescription={"registration"} userName={formData.email} signInExperience={false}/>);
  }
};

export default SignUpForm;
