import React, {useEffect} from 'react';
import './SocialSignins.css';
import GoogleIcon from '../../assets/icons/Google.svg';
import FacebookIcon from '../../assets/icons/Facebook.svg';
import AppleIcon from '../../assets/icons/Apple.svg';
import {useNavigate} from "react-router-dom";
import {CognitoHostedUIIdentityProvider} from '@aws-amplify/auth'
import {Auth} from 'aws-amplify';

const SocialSignins = ({cloakFunction}) => {
  const navigate = useNavigate();

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        navigate("/welcome");
      })
      .catch(() => {});

  });
  const handleGoogleSignIn = () => {
    cloakFunction();
    Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google});
  };

  const handleAppleSignIn = () => {
    cloakFunction();
    Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Apple});
  };

  const handleFacebookSignIn = () => {
    cloakFunction();
    Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Facebook});
  };


  return (
    <div className="social-sign-in-buttons-container">
      <button type="button" className="social-sign-in" onClick={handleGoogleSignIn}>
        <img src={GoogleIcon} alt="Google Sign-In"/>
      </button>

      <button type="button" className="social-sign-in" onClick={handleAppleSignIn}>
        <img src={AppleIcon} alt="Sign In With Apple"/>
      </button>

      <button type="button" className="social-sign-in" onClick={handleFacebookSignIn}>
        <img src={FacebookIcon} alt="Facebook Sign-In"/>
      </button>
    </div>
  );
};

export default SocialSignins;
