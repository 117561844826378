import React, {useState, useEffect, useContext} from 'react';
import './DocumentViewer.css';
import AxiosContext from "../../services/AxiosContext";

const DocumentViewer = ({type}) => {
  const [documentData, setDocumentData] = useState({title: '', content: ''});
  const axios = useContext(AxiosContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/public/docs/${type}`);
        setDocumentData(response.data);
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };

    fetchData();
  }, [type]);

  return (
    <div className={'doc-container'}>
      <h1>{documentData.title}</h1>
      {/* Use dangerouslySetInnerHTML with caution and ensure your content is sanitized */}
      <div dangerouslySetInnerHTML={{__html: documentData.content}}/>
    </div>
  );
};

export default DocumentViewer;
