import {BrowserRouter as Router, Navigate, Route, Routes, useLocation} from 'react-router-dom';
import SignUpForm from './components/signup/SignUpForm'; // Your sign-up form component
import './fonts/stylesheet.css';
import './App.css';
import {Auth} from 'aws-amplify';
import React from 'react';
import SignInForm from "./components/signin/SignInForm";
import ThankYouPage from "./views/Welcome/ThankYouPage";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import PageNotFound from "./views/System/PageNotFound";
import Logout from "./views/Logout/Logout";
import PublicQuiz from "./views/PublicQuiz/PublicQuiz";
import AxiosContext from "./services/AxiosContext";
import axios from "axios";
import ConditionalRoute from "./components/ConditionalRoute/ConditionalRoute";
import DocumentViewer from "./components/DocumentViewer/DocumentViewer";
import Footer from "./components/Footer/Footer";

Auth.configure({
  authenticationFlowType: 'CUSTOM_AUTH'
});

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_CONNECTED_API_BASE_URL}`,
});

const RemoveTrailingSlash = () => {
  let location = useLocation();

  if (location.pathname.endsWith('/')) {
    const withoutSlash = location.pathname.slice(0, -1);
    return <Navigate to={withoutSlash + location.search} replace/>;
  }

  return null;
};

const App = () => {
  //copy to clipboard
  return (
    <Router>
      <AxiosContext.Provider value={axiosInstance}>
        <RemoveTrailingSlash/>
        <Routes>
          <Route path="/" element={<ConditionalRoute>
            <PublicQuiz/>
          </ConditionalRoute>}/>

          <Route path="/quiz" element={
            <ConditionalRoute>
              <PublicQuiz/>
            </ConditionalRoute>
          }/>

          <Route path="/terms-of-service" element={<DocumentViewer type={'ts'}/>}/>
          <Route path="/privacy-policy" element={<DocumentViewer type={'pp'}/>}/>
          <Route path="/signup" element={<SignUpForm/>}/>
          <Route path="/signin" element={<SignInForm/>}/>
          <Route path="/logout"
                 element={
                   <ProtectedRoute>
                     <Logout/>
                   </ProtectedRoute>}
          />

          <Route
            path="/welcome"
            element={
              <ProtectedRoute>
                <ThankYouPage/>
              </ProtectedRoute>
            }
          />

          <Route path="*" element={<PageNotFound/>}/>
        </Routes>
        <Footer />
      </AxiosContext.Provider>
    </Router>
  )
}

export default App
