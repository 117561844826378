import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {PUBLIC_QUIZ_STATE_SUBMIT} from "../../utils/Constants";
import {Auth} from "aws-amplify";

const ConditionalRoute = ({children}) => {
  const location = useLocation();
  const quizStateSubmit = localStorage.getItem(PUBLIC_QUIZ_STATE_SUBMIT);

  Auth.currentAuthenticatedUser()
    .catch(() => {
      console.log("not logged in");
      // Check if the PUBLIC_QUIZ_STATE_SUBMIT is not set or false
      if (!quizStateSubmit || quizStateSubmit === 'false') {
        return children; // Render the component
      }
    });
  // Redirect to another route if condition is not met
  // Replace '/other-route' with the path you want to redirect to
  return <Navigate to="/welcome" state={{from: location}} replace/>;
};

export default ConditionalRoute;
