import React, {useState, useEffect, useContext} from 'react';
import './PublicQuiz.css';
import QuizQuestion from '../../components/QuizQuestion/QuizQuestion';
import LoadingCloak from "../../components/LoadingCloak/LoadingCloak";
import RegularButton from "../../components/contorols/RegularButton/RegularButton";
import {useNavigate} from "react-router-dom";
import {PUBLIC_QUIZ_ID} from "../../utils/Constants";
import AxiosContext from "../../services/AxiosContext";
import {getPublicQuizzes} from "../../services/publicQuizzesService";

const PublicQuiz = () => {
  const [userResponses, setUserResponses] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [quizData, setQuizData] = useState(null);
  const [progress, setProgress] = useState(0);
  const axios = useContext(AxiosContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const data = await getPublicQuizzes(axios)
        const savedQuizState = localStorage.getItem(PUBLIC_QUIZ_ID);
        if (savedQuizState) {
          const {currentIndex, userResponses, progress} = JSON.parse(savedQuizState);
          setCurrentQuestionIndex(currentIndex);
          setUserResponses(userResponses);
          setProgress(progress);
        }
        setQuizData(data.items[0]);
      } catch (error) {
        console.error('Error fetching quiz data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);


  const handleChoiceSelected = (questionId, choice) => {
    // Update progress
    // Record user response
    const newResponse = {
      question_id: questionId.toString(),
      question_name: quizData.questions[currentQuestionIndex].question,
      choices: [{choice_id: choice.order.toString(), choice: choice.choice}]
    };

    let updatedResponses = [];
    setUserResponses(oldResponses => {
      // Preventing duplicate entries for the same question
      const filteredResponses = oldResponses.filter(response => response.question_id !== questionId.toString());
      updatedResponses = [...filteredResponses, newResponse];

      const updatedProgress = progress + (100 / quizData.questions.length);
      setProgress(updatedProgress);

      let updatedIndex;
      if (currentQuestionIndex < quizData.questions.length - 1) {
        updatedIndex = currentQuestionIndex + 1;
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
      localStorage.setItem(PUBLIC_QUIZ_ID, JSON.stringify({
        currentIndex: currentQuestionIndex === quizData.questions.length - 1? quizData.questions.length : updatedIndex,
        userResponses: updatedResponses,
        progress: updatedProgress
      }));
      // Move to the next question
      if (currentQuestionIndex === quizData.questions.length - 1) {
        navigate('/signin');
      }

      return updatedResponses;
    });
  };


  return (
    <div>
      {isLoading && <LoadingCloak/>}
      <div className="public-quiz-form-container">

        <div className="progress-bar-container">
          <div className="progress-top-section">
            <div className="progress-label">{progress.toFixed(0)}%</div>
            <div className="progress-skip-button">
              <RegularButton buttonText="Return Later" showStars={false} onClickHandler={() => navigate("/signin")}/>
            </div>
          </div>
          <div className="progress-bar">
            <div className="progress" style={{width: `${progress}%`}}></div>
          </div>
        </div>

        {quizData && quizData.questions && quizData.questions.length > 0 && (
          <QuizQuestion
            key={quizData.questions[currentQuestionIndex].id}
            question={quizData.questions[currentQuestionIndex].question}
            choices={quizData.questions[currentQuestionIndex].choices}
            type="radio"
            onChoiceSelected={(choice) => handleChoiceSelected(quizData.questions[currentQuestionIndex].id, choice)}
          />
        )}
      </div>
    </div>

  );
};

export default PublicQuiz;

